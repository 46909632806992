import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="selector"
export default class extends Controller {
  redirect() {
    const selectedPath = event.target.value;
    if (selectedPath) {
      window.location.href = selectedPath;
    }
  }
}
